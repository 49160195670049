import React from "react";
import {graphql} from "gatsby";
import ImageGallery from 'react-image-gallery';

import Layout from "../../../components/layout";
import HelpMethodsSection from "../../../components/helpMethodsSection";

function GalleryPost(props) {
  const {data} = props;
  const {title, date, description, images} = data.markdownRemark.frontmatter;

  const galleryImages = images.map(({ image, caption }) => ({
    original: image,
    thumbnail: image,
    description: caption,
  }));

  return (
    <Layout activeLink="/stay-informed" pageTitle={`Gallery: ${title}`} pageDescription={description} {...props}>
      <div className="p-10 text-center pb-0">
        <p className="text-4xl mb-5">{title}</p>
        <p className="text-sm mb-5">{date}</p>
        {description?.length > 0 && <p className="text-xl mb-10">{description}</p>}
      </div>

      <div>
        <ImageGallery items={galleryImages} showThumbnails/>
      </div>

      {/*<div className="flex flex-row flex-wrap justify-center">*/}
      {/*  {images.map(({image, caption}, index) => (*/}
      {/*    <a key={index} href={image} target="_blank" className="block">*/}
      {/*      <img src={image} className="w-full max-w-sm p-10 rounded-md hover:scale-110"/>*/}
      {/*    </a>*/}
      {/*  ))}*/}
      {/*</div>*/}

      <HelpMethodsSection/>
    </Layout>
  )
}

export default GalleryPost;

export const galleryPostQuery = graphql`
  query NewsPostQuery($slug: String) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      frontmatter {
        date(formatString: "MMMM D, YYYY")
        title
        description
        images {
          image
          caption
        }
      }
    }
  }
`;
